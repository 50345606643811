var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"add-contact-person-wrap"},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.defaultFormItemStyle,false),[_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('page.change.oldpassword')}},'a-form-item',_vm.defaultFormItemStyle,false),[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              { required: true, message: _vm.$t('Old.Password.Cannot.be.empty') }
            ]
          }
        ]),expression:"[\n          'password',\n          {\n            rules: [\n              { required: true, message: $t('Old.Password.Cannot.be.empty') }\n            ]\n          }\n        ]"}],attrs:{"type":"password","name":"password","placeholder":_vm.$t('page.change.password.oldpassword')}})],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('page.change.newpassword')}},'a-form-item',_vm.defaultFormItemStyle,false),[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newPassword',
          {
            validateFirst: true,
            rules: _vm.passwordRules
          }
        ]),expression:"[\n          'newPassword',\n          {\n            validateFirst: true,\n            rules: passwordRules\n          }\n        ]"}],attrs:{"name":"password","type":"password","placeholder":_vm.$t('page.change.password.newPassword')}})],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('page.change.confirmpassword')}},'a-form-item',_vm.defaultFormItemStyle,false),[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surePassword',
          {
            validateFirst: true,
            rules: _vm.confirmpasswordRules
          }
        ]),expression:"[\n          'surePassword',\n          {\n            validateFirst: true,\n            rules: confirmpasswordRules\n          }\n        ]"}],attrs:{"type":"password","name":"password","placeholder":_vm.$t('page.change.password.again')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }