
import { Component, Emit, VModel, Vue } from 'vue-property-decorator'
import { passwordValidate } from '../../../../utils/util'
import { I18n as i18n } from '@/i18n'
@Component({
  name: 'paswordInput',
  components: {}
})
export default class paswordInput extends Vue {
  form!: any

  defaultFormItemStyle: any = {
    labelCol: {
      span: 5
    },
    wrapperCol: {
      span: 19
    }
  }

  @VModel({ type: Object }) data!: any

  created() {
    this.form = this.$form.createForm(this, {
      name: 'pasword_input',
      onValuesChange: this.onValuesChange
    })
  }

  get confirmpasswordRules() {
    const confirmpasswordValidator = (rule: any, value: any, callback: any) => {
      console.log(this.form.getFieldValue('newPassword'))
      let newPassword = this.form.getFieldValue('newPassword')
      console.log('checkPasswordValidator', rule, value)
      if (value) {
        if (value === newPassword) {
          callback()
        } else {
          callback(callback(i18n.t('two.password.error')))
        }
      } else {
        callback()
      }
    }
    return [
      { required: true, message: i18n.t('Password.Cannot.be.empty') },
      {
        validator: confirmpasswordValidator
      }
    ]
  }
  get passwordRules() {
    const checkPasswordValidator = (rule: any, value: any, callback: any) => {
      let oldPassword = this.form.getFieldValue('password')
      if (value) {
        if (value !== oldPassword) {
          try {
            passwordValidate(value)
            callback()
          } catch (err: any) {
            callback(err)
          }
        } else {
          callback(i18n.t('Oldnew.password.error'))
        }
      } else {
        callback()
      }
    }
    return [
      { required: true, message: i18n.t('Please.input.password') },
      {
        validator: checkPasswordValidator
      }
    ]
  }

  @Emit('change')
  notifyDataChanged(values: any) {
    if (this.data && this.data.key) {
      //in edit mode
      values['key'] = this.data.key
    }
    this.data = values
    return values
  }

  onValuesChange(props: any, values: any) {
    console.log('ContactPersonInput onValuesChange', props, values)
    // this.notifyDataChanged(values)
  }

  async save() {
    const values = await this.form.validateFields()
    if (this.data && this.data.key) {
      //in edit mode
      values['key'] = this.data.key
    }
    return values
  }
}
